import React, { useRef } from 'react';
import heroimage from "../assets/bg.svg";

const AboutSection: React.FC = () => {
  const aboutRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (aboutRef.current) {
  //       aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  //     }
  //   }
  //   , 1000);
  //   return () => clearTimeout(timeout);
  // }
  // ,[aboutRef]);


  return (
    <div className=" pt-0 " id="about" ref={aboutRef}>
      <div className="container text-center text-lg-start">
        <div className="row align-items-center g-4 g-lg-5">
          <div className="col-12 col-lg-6">
            <img src={heroimage} alt="hero" className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6">
            <h6 className="d-inline-block bg-yellow border-radius px-3 py-2 line-height-140 font-small uppercase letter-spacing-1 mb-3">
              <span className="text-gradient-6">About us</span>
            </h6>
            <h2 >We help businesses to become inescapable in a digital world</h2>
            <p style={{ textAlign: "justify" }}>
              We are a team of talented digital experts who constantly strive to improve our skills and knowledge to provide the best solutions for our clients’ needs. We are passionate about what we do and are always ready to face new challenges.
            </p>
            <button type="button" className="button button-lg button-rounded button-font-2 button-gradient-6 mt-4" >Learn more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
