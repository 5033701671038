// ReCaptcha.tsx

import React, { useState, useCallback } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

interface ReCaptchaProps {}

const ReCaptcha: React.FC<ReCaptchaProps> = () => {
    const [token, setToken] = useState("");
    const [key, setKey] = useState(0); // Key used to reset the component
    // const recaptchaRef = useRef<any>(null);

    const setTokenFunc = useCallback((getToken: any) => {
        setToken(getToken);
    }, []);

    const resetRecaptcha = useCallback(() => {
        // Incrementing the key to reset the ReCaptcha component
        setKey(prevKey => prevKey + 1);
    }, []);

    const handleOnExpired = useCallback(() => {
        resetRecaptcha();
    }, [resetRecaptcha]);

    return (
        <div style={{}}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_REPTCHA_SITE_KEY as string}>
                <GoogleReCaptcha
                    onVerify={setTokenFunc}
                    key={key} // Setting key prop to trigger component reset
                    // @ts-ignore
                    // ref={recaptchaRef} // Ignore the type error for ref
                />
            </GoogleReCaptchaProvider>
        </div>
    );
};

export default ReCaptcha;
