import React, { useState, useRef, useEffect } from 'react';

interface OTPInputProps {
  length: number; // Length of OTP
  autoFocus?: boolean; // Whether to focus on the input on mount
  onComplete: ({
    otp,
    type,
  }: {
    otp: string;
    type: string;
  }) => void; // Callback function when OTP is complete'
  type: string;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, autoFocus = true, onComplete, type }) => {
  const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
  const otpInputs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (autoFocus && otpInputs.current[0]) {
      otpInputs.current[0]?.focus();
    }
  }, [autoFocus]);

  const handleInput = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e?.target?.value.slice(-1); // Only take the last character entered
    if (/^\d$/.test(inputValue)) { // Check if input is a single digit
      const newOTP = [...otp];
      newOTP[index] = inputValue;
      setOTP(newOTP);
      if (inputValue !== '' && index < length - 1) {
        otpInputs.current[index + 1]?.focus();
      } else if (newOTP.every((value) => value !== '')) {
        onComplete({ otp: newOTP.join(''), type: type });
      }
    }
  };
 
  const handlePaste = (index: number, e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    if (/^\d$/.test(pastedData)) {
      const syntheticEvent = {
        target: {
          value: pastedData.charAt(0)
        }
      };
      handleInput(index, syntheticEvent as React.ChangeEvent<HTMLInputElement>); // Only handle the first character of the pasted data
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (index > 0 && otp[index] === '') {
        otpInputs.current[index - 1]?.focus();
      }
      // Reset the current input value if Backspace is pressed
      const newOTP = [...otp];
      newOTP[index] = '';
      setOTP(newOTP);
      // Call the onComplete callback when any of the input is empty
      if (newOTP.some((value) => value === '')) {
        onComplete({ otp: newOTP.join(''), type: type });
      }
  };
}

  return (
    <div className='otp-container w-100 '>
      {Array.from({ length }, (_, index) => (
        <input
          key={index}
          type="number"
          maxLength={1}
          value={otp[index]}
          onChange={(e) => handleInput(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(input) => {
            otpInputs.current[index] = input;
          }}
          onPaste={(e) => handlePaste(index, e)}
          className='otp-input'
        />
      ))}
    </div>
  );
};

export default OTPInput;
