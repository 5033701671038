import React from 'react';
import GpsSvg from '../assets/svg/GpsSvg';
import VerficationSvg from '../assets/svg/VerficationSvg';
import ReportSvg from '../assets/svg/ReportSvg';

const Features: React.FC = () => {
  return (
    <div className="">
      <div className="container text-center icon-5xl">
        <div className="row g-4">
          <div className="col-12 col-lg-4">
            <div className=" bg-light-turquiose border-radius p-4 p-lg-5 hover-float">
              <div className="text-dark mb-4">
                <GpsSvg fill="#000000" width="30px" height="30px" />
              </div>
              <h5 className="text-dark"> GPS-Enhanced Attendance Tracking</h5>
              <p className="text-dark-06">TimeMarkr harnesses the power of GPS to ensure accurate and reliable attendance tracking. With our app, you can effortlessly mark your attendance from anywhere, whether in the office,  or remotely.</p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="bg-yellow-light border-radius p-4 p-lg-5 hover-float">
              <div className="text-dark mb-4">
              <VerficationSvg fill="#000000" width="30px" height="30px" />
              </div>
              <h5 className="text-dark"> Selfie Verification</h5>
              <p className="text-dark-06"> Bid farewell to buddy punching and fraudulent attendance! TimeMarkr employs advanced facial recognition technology to verify your presence through a quick selfie. This guarantees the integrity of attendance records and adds an extra layer of security.
</p>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className=" bg-light-turquiose border-radius p-4 p-lg-5 hover-float">
              <div className="text-dark mb-4">
               <ReportSvg fill="#000000" width="30px" height="30px" />
              </div>
              <h5 className="text-dark"> Comprehensive Reports</h5>
              <p className="text-dark-06"> Keep track of attendance trends and analyze data effortlessly. TimeMarkr provides detailed reports that help you identify patterns, track absenteeism, and make informed decisions for resource allocation and team management.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
