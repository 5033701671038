import React from "react";

import logo from "../assets/logo.svg";
interface Props {
  // Define props here
}

const RefundPolicy: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid p-3 d-flex justify-content-center">
      <div className="card col-md-8 col-sm-12 border-0">
        <div className="card-body px-2">
          <h5 className="card-title text-center">
            <img src={logo} alt="TimeMarkr Logo" style={{ width: "30%", height: "30%" }} />
          </h5>
          <h5
            className="card-title text-center "
            style={{ marginBottom: "20px", padding: "10px" }}
          >
            <strong className="">TimeMarkr Refund Policy</strong>
          </h5>

<h6 style={{ textAlign: "justify" }}>
   <strong className="">Introduction</strong> 
</h6>
          <p style={{ textAlign: "justify" }}>
            At Allsoft Consulting Private Limited, we are committed to
            transparency and customer satisfaction. Our software TimeMarkr,
            provides a comprehensive trial period, allowing full access to all
            features before making a purchase decision. This policy is designed
            to ensure that our customers have the opportunity to evaluate the
            software in its entirety, ensuring it meets their needs before
            committing to a purchase.
          </p>
        
          <p style={{ textAlign: "justify" }}>
            <strong>Refund Policy:
 </strong> <br />
 All purchases made for TimeMarkr are final and non-refundable. We provide a comprehensive trial period to allow our users to fully evaluate the software before purchasing. This trial is intended to help you determine if the software suits your needs, thus eliminating the necessity for refunds once a purchase is made.

          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Trial Period Details: </strong> <br />
            <ul  style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                 <li  className="mb-2">
              <strong> Duration: </strong> The trial period lasts for 30 Days, during which you have full access to all features of TimeMarkr.
              </li>
                 <li  className="mb-2">
              <strong> Access:</strong> You are granted complete access to all tools, features, and capabilities, mirroring the full version of the software.

              </li>
                 <li  className="mb-2">
              <strong>Support:</strong>  Our customer support team is available to assist with any questions or issues during the trial period.

              </li>
             
            </ul>
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Why We Have a No-Refund Policy:
 </strong> <br />
            <ul  style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li  className="mb-2">
              <strong>Full Feature Access:</strong>  The trial period is designed to give you ample time to explore and evaluate the software in depth, ensuring it meets your requirements.

              </li>
                 <li  className="mb-2">
              <strong> Resource Allocation:</strong> Upon purchase, we allocate resources, including support, maintenance, and ongoing development costs, based on our user base. This policy helps us manage resources effectively and continue providing high-quality service.

              </li>
                 <li  className="mb-2">
              <strong>Customer Commitment:</strong>  Our goal is to ensure that all our customers are sure of their purchase decision, reinforcing the value of our software through its comprehensive trial
              </li>
            </ul>
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Exceptional Circumstances:
 </strong> <br />
 There are no exceptions to this Refund Policy. We encourage all potential users to take advantage of the trial period to make an informed purchase decision.

           
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Contact Information:
</strong> <br />
Should you have any questions about the trial period or need assistance during your evaluation of TimeMarkr, please do not hesitate to contact our customer support team at info@timemarkr.com
          </p>
      
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
