import React from 'react';

import PageLayout from '../layout/PageLayout';
import HeroSection from './HeroSection';
import Features from './Features';
import AboutSection from './AboutSection';
import WorkingApproach from './WorkingApproach';
import PricingPlansSection from './PricingPlansSection';
import AccordionSection from './AccordionSection';
import ContactForm from './ContactPage';


interface Props {
  // Define props here
}

const Landing: React.FC<Props> = (props) => {
  return (
    <div style={{position: 'relative',display:"flex",flexDirection:"column",minHeight:"100vh"}}>
   
          <PageLayout>
            <HeroSection />
          </PageLayout>
          <PageLayout>
            <Features />
          </PageLayout>
          <PageLayout>
            <AboutSection />
          </PageLayout>
          <PageLayout>
            <WorkingApproach />
          </PageLayout>
          <PageLayout>
            <PricingPlansSection />
          </PageLayout>
          <PageLayout>
            <AccordionSection />
          </PageLayout>
          <PageLayout>
            <ContactForm />
          </PageLayout>
         
          </div>
  );
};

export default Landing;