import {  useState } from 'react';
import styles from "../styles/Navbar.module.css";
import logo from "../assets/logo.svg";
import FacebookSvg from '../assets/svg/FacebookSvg';
import InstagramSvg from '../assets/svg/InstagramSvg';
import LinkedInSvg from '../assets/svg/LinkedInSvg';

function Navbar() {
  const [isActive, setIsActive] = useState(false);

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };



  return (
    <div style={{
      width: '100%',
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
      background: "white", // Change to "transparent" to make the navbar "sticky"
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
    }}>
      <header className={`${styles.header}`}>
        <nav className={`${styles.navbar}`}>
          <a href='/#home' className={`${styles.logo}`} style={{ flex: 1 }}>
            <img src={logo} alt="logo" className="logo-img" />
          </a>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`} style={{ flex: 2 }}>
              <li onClick={removeActive}>
                <a href='/#home' className={`${styles.navLink}`} onClick={() => scrollToSection('home')}>Home</a>
              </li>
              <li onClick={removeActive}>
                <a href='/#about' className={`${styles.navLink}`} onClick={() => scrollToSection('about')}>About</a>
              </li>
              <li onClick={removeActive}>
                <a href='/#register' className={`${styles.navLink}`} onClick={() => scrollToSection('register')}>Register</a>
              </li>
              <li onClick={removeActive}>
                <a href='#contact' className={`${styles.navLink}`} onClick={() => scrollToSection('contact')}>Contact</a>
              </li>

            </ul>
            <div className="social-icons ">
              <div className="social-icons__icon" onClick={() => window.open("https://www.facebook.com/TimeMarkr/", "_blank")}>
                <FacebookSvg />
              </div>
              <div className="social-icons__icon" onClick={() => window.open("https://www.instagram.com/timemarkr/", "_blank")}>
                <InstagramSvg />
              </div>
              <div className="social-icons__icon" onClick={() => window.open("https://www.linkedin.com/showcase/timemarkr/", "_blank")}>
                <LinkedInSvg />
              </div>
            </div>
          </div>


          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`} onClick={toggleActiveClass}>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Navbar;
