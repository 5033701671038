
import axios, { AxiosResponse } from "axios";


interface ApiResponse {
    data : {
        message: string;
        status: string;
        data :any;
    
    },

message: string;
    status: string;
}


// API for user registration
export const contactEnquiry = async (data: any): Promise<AxiosResponse<ApiResponse>> => {
    try {
        const response = await axios.post<any, AxiosResponse<ApiResponse>>(
            'https://admin.timemarkr.com/api/common/contactUs',
            data
        );
        return response;
    } catch (error) {
        throw error;
    }
};
