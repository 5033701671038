import { useEffect, useState } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Preloader from './components/Preloader';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes';


function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true); // Set isLoaded to true after loading
    }, 1000); // Simulated loading time (adjust as needed)

    // Clean up if needed
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="App">
      {!isLoaded && <Preloader />} {/* Render preloader until isLoaded is true */}
      {isLoaded && (
        <>

          <Navbar />
          <AppRoutes />
          <Footer />
        </>
      )}

    </div>
  );
}

export default App;
