import React from 'react';

interface Props {
  // Define props here
children: React.ReactNode;
}

const PageLayout: React.FC<Props> = (props) => {
  return (
    <div className='layout '> 
      {props.children}
    </div>
  );
};

export default PageLayout;


