import React from 'react';
import logo from "../assets/logo.svg";
interface Props {
  // Define props here
}

const TermsAndCondition: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid p-3 d-flex justify-content-center">
      <div className="card col-md-8 col-sm-12 border-0">
        <div className="card-body px-2">
          <h5 className="card-title text-center">
            <img src={logo} alt="TimeMarkr Logo" style={{ width: "30%", height: "30%" }} />
          </h5>
          <h5 className="card-title text-center " style={{ marginBottom: "20px", padding: "10px" }}>
            <strong className=''>TimeMarkr Terms And Conditions</strong>
          </h5>

          <p style={{ textAlign: "justify" }}>
            <strong> Acceptance of Terms:</strong>  By using TimeMarkr, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, you may not use the service. TimeMarkr reserves the right to update these terms and conditions at any time without notice. It is the user's responsibility to review these terms and conditions regularly to ensure compliance.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong> Description of Service:</strong>  TimeMarkr is an attendance management system designed to track employee attendance, manage time-off requests, and generate reports for administrative purposes. The service is accessible via the TimeMarkr website and mobile app.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Accuracy of Information:  </strong>  Users are responsible for ensuring the accuracy of all information entered into TimeMarkr, including but not limited to employee attendance records, time-off requests, and schedule information. TimeMarkr is not responsible for any errors or inaccuracies in user data.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Data Security:  </strong>  TimeMarkr employs industry-standard security measures to protect user data. However, users acknowledge that no system is entirely secure, and TimeMarkr cannot guarantee the security of user information. Users are responsible for maintaining the confidentiality of their login credentials and for any activity that occurs under their account.


          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Use of Data::  </strong>   TimeMarkr may collect and store user data for the purpose of providing the service. This data may include but is not limited to employee names, attendance records, and time-off requests. TimeMarkr will not share this data with third parties without the consent of the user.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Prohibited Activities:  </strong>   Users may not use TimeMarkr for any unlawful purpose or in any way that violates these terms and conditions. Prohibited activities include but are not limited to hacking, spamming, or distributing malware. TimeMarkr reserves the right to terminate the account of any user who engages in prohibited activities.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Limitation of Liability:  </strong>   TimeMarkr is provided "as is" without any warranty of any kind. In no event shall TimeMarkr or its affiliates be liable for any damages arising out of the use or inability to use the service. This includes but is not limited to direct, indirect, incidental, or consequential damages.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Indemnification:  </strong>   Users agree to indemnify and hold TimeMarkr and its affiliates harmless from any claims, losses, or damages arising out of the use of the service. This includes but is not limited to legal fees and expenses.
          </p>
          <p style={{ textAlign: "justify" }}>
            <strong>Termination:  </strong>   TimeMarkr reserves the right to terminate the account of any user who violates these terms and conditions. Termination may occur without notice and may result in the loss of all data associated with the account.
          </p>
        </div>






      </div>
    </div>
  );
};

export default TermsAndCondition;