import React from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import '@dotlottie/react-player/dist/index.css';

interface LottieAnimationProps {
  src: string;
  width: string;
  height: string;
  background?: string;
  speed?: number;
  loop?: boolean;
  autoplay?: boolean;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
    src,
    width = '10px',
    height = '10px',
    background = 'transparent',
    speed = 1,
    loop = true,
    autoplay = true,
  }) => {
    return (
      <DotLottiePlayer
        src={src}
        background={background}
        autoplay={autoplay}
        loop={loop}
        speed={speed}
        style={{ 
            width: width, 
            height: height 
            }}
      
       

      />
    );
  };
  
    export default LottieAnimation;