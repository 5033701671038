import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import error404Image from "../assets/404.png";
import { Image } from "react-bootstrap";

const Error404 = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/')
    }
    return (
        <div className="container" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: 'calc(100vh - 315px)' }}>

            <Image alt="error-404" src={error404Image} style={{ objectFit: 'contain', width: '90%', maxHeight: 300 }} />
            <br />
            <h1>Oops We Didn't find what you looking for</h1>
            <br />
            <Button onClick={handleClick} variant="contained"
                className="button button-lg button-rounded button-font-2 button-gradient-6 lightbox-media-link m-2">Go to Home</Button>
        </div>
    )
}

export default Error404