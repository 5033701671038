

function Preloader() {
 

  return  (
    <div className="preloader">
      {/* Your preloader content goes here */}
      <div className="spinner"></div>
    </div>
  );
}

export default Preloader;
