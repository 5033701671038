import React from 'react';

interface Props {
  // Define props here
}

const CorrectSvg: React.FC<Props> = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 15 15" fill="#EDC213" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z" fill="#EDC213" />
    </svg>
  );
};

export default CorrectSvg;

