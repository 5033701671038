import React from 'react';

interface Props {
  // Define props here
  width?: string;
  height?: string;
  fill?: string;
}

const FacebookSvg: React.FC<Props> = (props) => {
  return (
    <svg
    version="1.1"
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width || "32px"}
    height={props.height || "32px"}
    fill={props.fill || "#000000"}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <style>{`.feather_een{fill:#0B1719;}`}</style>
    <path
      className="feather_een"
      d="M8,10.5L8,10.5C8,10.224,8.224,10,8.5,10h3c0.276,0,0.5,0.224,0.5,0.5v0c0,0.276-0.224,0.5-0.5,0.5h-3
  C8.224,11,8,10.776,8,10.5z M8.5,13h3c0.276,0,0.5-0.224,0.5-0.5v0c0-0.276-0.224-0.5-0.5-0.5h-3C8.224,12,8,12.224,8,12.5v0
  C8,12.776,8.224,13,8.5,13z M30,8v18c0,1.657-1.343,3-3,3H5c-1.657,0-3-1.343-3-3V8c0-1.657,1.343-3,3-3c0,0,0.448-1,1-1h3.092
  C9.299,3.419,9.849,3,10.5,3s1.201,0.419,1.408,1H14c0.552,0,1,0.448,1,1h12C28.657,5,30,6.343,30,8z M6,15h8V5h-2v2.5
  c0,0.892-0.783,1.605-1.697,1.487C9.547,8.89,9,8.21,9,7.448V7.25c0-0.276,0.224-0.5,0.5-0.5h0c0.276,0,0.5,0.224,0.5,0.5V7.5
  c0,0.303,0.271,0.544,0.584,0.493C10.83,7.953,11,7.721,11,7.472L11,5H6V15z M28.976,26.242C28.447,26.708,27.76,27,27,27H5
  c-0.76,0-1.447-0.292-1.976-0.758C3.145,27.231,3.978,28,5,28h22C28.022,28,28.855,27.231,28.976,26.242z M29,8c0-1.105-0.895-2-2-2
  H15v9c0,0.552-0.448,1-1,1H6c-0.552,0-1-0.448-1-1l0-9C3.895,6,3,6.895,3,8v16c0,1.105,0.895,2,2,2h22c1.105,0,2-0.895,2-2V8z
   M26.691,14.038C26.63,14.013,26.565,14,26.5,14h-4c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5h2.793l-3,3h-1.586l-1.854-1.854
  c-0.195-0.195-0.512-0.195-0.707,0L15.293,19H11.5c-0.133,0-0.26,0.053-0.354,0.146L8.293,22H5.5C5.224,22,5,22.224,5,22.5
  S5.224,23,5.5,23h3c0.133,0,0.26-0.053,0.354-0.146L11.707,20H15.5c0.133,0,0.26-0.053,0.354-0.146l2.646-2.646l1.646,1.646
  C20.24,18.947,20.367,19,20.5,19h2c0.133,0,0.26-0.053,0.354-0.146L26,15.707V18.5c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-4
  c0-0.065-0.013-0.13-0.038-0.191C26.911,14.187,26.813,14.089,26.691,14.038z"
    />
  </svg>
  );
};

export default FacebookSvg;