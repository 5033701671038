import React, { useRef } from 'react';
import heroimage from "../assets/landing.webp";
import CorrectSvg from '../assets/svg/CorrectSvg';


const HeroSection: React.FC = () => {
  const homeRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (homeRef.current) {
  //     window.scrollTo({ top: 0, behavior: 'auto' });

  //   }
  // }
  // ,[homeRef]);




  return (
    <div className="  mt-4 mt-lg-5" id="home" ref={homeRef} style={{
      background: "linear-gradient(0deg, #FFE7B8 0%, #FFFFFF 100%)",





    }}>
      <div className="container">
        <div className="row        g-4 g-lg-5  align-items-end" style={{
          height: "100%"
        }}>
          <div className="col-12 col-lg-5 offset-lg-1 order-lg-2 text-center text-lg-end  d-flex justify-content-center align-items-flex-end " style={{
            height: "100%"
          }}>

            <img src={heroimage} alt="hero" className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6 order-lg-1 text-center  text-lg-start">
            <ul className="list-inline mb-3">
              <li className="list-inline-item color-grey fs-6 ">
                <span className="m-2 ">
                  <CorrectSvg />
                </span>
                Professional Team</li>
              <li className="list-inline-item">  <span className="m-2">
                <CorrectSvg />
              </span>Fast & Friendly Support</li>
            </ul>
            <h1 className="fw-medium nunito display-5  text-justify w-100  mb-3 lh-base">Empower Your Workforce: Smart Attendance Tracking with GPS & Selfie Verification by TimeMarkr</h1>
            <div className="mt-4 pb-2 mt-lg-5 d-flex justify-content-center justify-content-lg-start flex-wrap">

              <div className="d-flex align-items-center m-2 cursor-pointer" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.allsoft.timemarkr&hl=en", "_blank")}>
                <img alt="TimeMarkr google play app link" className="news-app-promo__play-store" src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg" width="200" height="auto" style={{
                  backgroundColor: "black",
                  borderRadius: "14px",
                  border: "none"
                }} />
              </div>
              <div className="d-flex align-items-center cursor-pointer m-2 " onClick={() => window.open("https://apps.apple.com/us/app/timemarkr/id1581231233", "_blank")}>


                <img alt="TimeMarkr appstore app link" className="news-app-promo__app-store" src="//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg" width="200" height="auto" style={{
                  backgroundColor: "black",
                  borderRadius: "14px",
                  border: "none"
                }} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
