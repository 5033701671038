import React, { useRef, useState } from "react";
import { TextField, Button, Snackbar, Link } from "@mui/material";
import EmailSvg from "../assets/svg/EmailSvg";
import PhoneSvg from "../assets/svg/PhoneSvg";
import LocationSvg from "../assets/svg/LocationSvg";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { contactEnquiry } from "../services/contactusService";
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

interface ContactFormProps {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

interface ContactFormValues {
  name: string;
  mobile_number: string;
  email_id: string;
  subject: string;
  query: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  const contactRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
    status: false,
  });
  const defaultValues: ContactFormValues = {
    name: "",
    mobile_number: "",
    email_id: "",
    subject: "",
    query: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    email_id: Yup.string().required("Email is required").email("Email is invalid"),
    subject: Yup.string().required("Subject is required"),
    query: Yup.string().required("Query is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, },
  } = useForm<ContactFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const submitHandler = (data: ContactFormValues) => {
    setIsLoading(true)
    contactEnquiry(data).then((response) => {
      setSnackbarOpen({
        open: true,
        message: response.data.message,
        status: response.data.status === "success" ? true : false,
      });

      reset();

    }
    ).finally(() => {
      setIsLoading(false)
    });



  };
  const handleSnackbarClose = () => {
    setSnackbarOpen({ ...snackbarOpen, open: false });
  };
  return (
    <div className="" ref={contactRef} id="contact">
      <div className="container">
        <div className="w-100">
          <div className="row icon-4xl">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="pb-4">
                <EmailSvg />
              </div>
              <h6 className="fw-bold m-0">Email:</h6>
              <Link href="mailto:info@timemarkr.com" >info@timemarkr.com</Link>
            </div>

            <div className="col-12 col-sm-6 col-md-4">
              <div className="pb-4">
                <PhoneSvg />
              </div>
              <h6 className="fw-medium m-0">Phone:</h6>
              <Link href="tel:+9193038 52900" >+91 93038 52900</Link>
            </div>

            <div className="col-12 col-sm-6 col-md-4">
              <div className="pb-4">
                <LocationSvg />
              </div>
              <h6 className="fw-medium m-0">Address:</h6>
              <p>
                Allsoft Consulting Private Limited, Office 408, Fourth Floor,
                Magneto Offizo, GE Road, Raipur, Chhattisgarh, INDIA
              </p>
            </div>
          </div>
          <div className="mt-5">
            <div className="contact-form">
              <form method="post" id="contactform" onSubmit={handleSubmit(submitHandler)}>
                <div className="row gx-3 gy-0">
                  <div className="col-12 col-sm-6">
                    <TextField
                      label="Name*"
                      id="name"
                      variant="outlined"
                      placeholder="Enter your name"
                      {...register("name")}
                      error={!!errors.name}
                      autoComplete="off"
                      helperText={errors.name ? errors.name.message : ""}
                      fullWidth
                      InputLabelProps={{ style: { color: '#383838' } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },



                        },





                      }}
                      style={{ marginBottom: "1rem" }}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <TextField
                      label="Email*"
                      id="email"
                      variant="outlined"
                      type="email"
                      placeholder="Enter your email"
                      autoComplete="off"
                      {...register("email_id")}
                      error={!!errors.email_id}
                      helperText={errors.email_id ? errors.email_id.message : ""}
                      fullWidth
                      InputLabelProps={{ style: { color: '#383838' } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },



                        },





                      }}
                      style={{ marginBottom: "1rem" }}
                    />
                  </div>
                </div>
                <div className="row gx-3 gy-0">
                  <div className="col-12 col-sm-6">
                    <Controller
                      name="mobile_number"
                      control={control}
                      rules={{ validate: (value) => matchIsValidTel(value) }}
                      render={({ field: { ref: fieldRef, value, ...fieldProps }, fieldState }) => (
                        <MuiTelInput
                          {...fieldProps}
                          ref={fieldRef}
                          forceCallingCode={true}
                          id="mobile_number"
                          label="Mobile Number*"
                          variant="outlined"
                          placeholder="Enter your mobile number"
                          error={!!errors.mobile_number}
                          helperText={errors.mobile_number ? errors.mobile_number.message : ""}
                          fullWidth
                          defaultCountry="IN"
                          value={value ?? ''}
                        />
                      )}
                    />


                    {/* <TextField
                      label="Mobile Number*"
                      variant="outlined"
                      placeholder="Enter your mobile number"

                      type="number"
                      error={!!errors.mobile_number}
                      helperText={errors.mobile_number ? errors.mobile_number.message : ""}
                      fullWidth
                      InputLabelProps={{ style: { color: '#383838' } }}
                      InputProps={{
                        startAdornment: "+91",
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },
                          // label color should be black


                        },



                      }}
                      style={{ marginBottom: "1rem" }}
                    /> */}
                  </div>
                  <div className="col-12 col-sm-6">
                    <TextField
                      label="Subject*"
                      id="subject"
                      variant="outlined"
                      placeholder="Enter your subject"
                      {...register("subject")}
                      error={!!errors.subject}
                      helperText={errors.subject ? errors.subject.message : ""}
                      fullWidth
                      InputLabelProps={{ style: { color: '#383838' } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },
                          // label color should be black


                        },





                      }}
                      style={{ marginBottom: "1rem" }}
                    />
                  </div>
                </div>
                <div className="row gx-3 gy-0">
                  <div className="col-12">
                    <TextField
                      label="Query*"
                      variant="outlined"
                      placeholder="Enter your query"
                      {...register("query")}
                      error={!!errors.query}
                      helperText={errors.query ? errors.query.message : ""}
                      fullWidth
                      InputLabelProps={{ style: { color: '#383838' } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },
                          // label color should be black


                        },
                      }}
                      style={{ marginBottom: "1rem" }}
                      multiline
                      rows={4}
                    />
                  </div>
                </div>
                <div className="mt-4 mt-lg-5 d-flex justify-content-center justify-content-lg-center flex-wrap w-100 ">
                  <Button
                    variant="contained"
                    color="primary"
                    className="button button-lg button-rounded button-font-2 button-gradient-6 lightbox-media-link m-2"
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      backgroundColor: "#FFD78A",
                      boxShadow: "none",
                      borderRadius: "50px",
                      color: "#000",
                      "&:hover": {
                        backgroundColor: "#FFD78A"


                      }
                    }}
                  >
                    {`${isLoading ? 'Sending...' : 'Send Message'} `}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarOpen.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor:
              snackbarOpen.status === true ? "#4caf50" : "#f44336",
          },
          position: "fixed",
          top: "80px",
          width: "100%",
        }}
      />
    </div>
  );
};

export default ContactForm;
