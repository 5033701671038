import React from 'react';
import logo from "../assets/logo.svg";

interface Props {
  // Define props here
}

const PrivacyPolicies: React.FC<Props> = (props) => {
  return (
    <div className="container-fluid p-3 d-flex justify-content-center">
  <div className="card col-md-8 col-sm-12 border-0">
    <div className="card-body px-2">
      <h5 className="card-title text-center">
        <img src={logo} alt='TimeMarkr Logo' style={{ width: "30%", height: "30%" }} />
      </h5>
      <h5 className="card-title text-center">
        <strong>TimeMarkr Privacy Notice</strong>
      </h5>
      <p style={{ textAlign: "justify" }}>
        <strong>Last Updated Aug 08, 2022</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        This document is an electronic record as per the Information Technology
        Act, 2000 (hereby referred to as the "Act") and rules made under it as
        applicable and provisions relating to electronic records in various
        statutes as amended by the Information Technology Act, 2000. This
        electronic record is generated by a computer system/mobile application
        and does not require any physical or digital signatures. This document
        is published in accordance with the provisions of the Information
        Technology (Intermediaries guidelines) Rules, 2011 that require
        publishing the rules and regulations, privacy policy, and Terms of Use
        for access or usage of the Website and App.
      </p>
      <p style={{ textAlign: "justify" }}>
        At Allsoft Consulting Private Limited (including its subsidiaries or
        affiliated companies, henceforth also referred to as ‘allsoft
        <sup>®</sup>’, ‘we’, or the ‘Company’), we understand that you trust us
        with confidential information. We believe that you have a right to know
        our practices regarding the information we may collect and use when you
        use our service or interact with us in any manner. allsoft<sup>®</sup>{" "}
        is a consulting company that enables organizations to manage their human
        resources. allsoft<sup>®</sup> Mobile Apps are also part of the same
        offering. A user may be either an entity, for example, an employer which
        has executed an agreement with allsoft<sup>®</sup> or with allsoft
        <sup>®</sup>’s resellers or distributors who provide allsoft<sup>®</sup>
        ’s services (“Customer “) or a Customer’s users for example a Customer’s
        employees, of the Services or users of the Website/App (“end user(s) “)
        (Customer and end user shall collectively be referred to as “users” or
        “you “).
      </p>
      <p style={{ textAlign: "justify" }}>
        This Privacy Policy describes the policies and procedures of allsoft
        <sup>®</sup> on the collection, use, access, correction, and disclosure
        of your personal information on TimeMarkr.com (the “Site”) and our
        Mobile Apps. This privacy policy does not relate to any personal
        information that TimeMarkr collects on behalf of, or under the
        direction, of its clients. When you log on to the site you will have
        access to the privacy policy of the employer/prospective employer that
        is a client of TimeMarkr, which shall explain how they process your
        personal information and your rights in relation to such information.
        Our clients may ask us to amend, update, or delete your personal
        information on behalf of our clients in which case we will do so in
        accordance with the terms of our contract with our clients.
      </p>
      <p style={{ textAlign: "justify" }}>
        Your personal information will include any information which, either
        alone or with other data, is reasonably available to us and relates to
        you (“Personal Information”). This Privacy Policy also covers any of
        your Personal Information that is provided to us and which is used in
        connection with the marketing of the services, features, or content we
        offer (the “Services”) to our clients and/or the support that we may
        give you in connection with the provision of our Services and the Mobile
        Apps.
      </p>
      <p style={{ textAlign: "justify" }}>
        This Privacy Policy does not apply to any third-party applications or
        software that can be accessed from the Site, the Services, or the Mobile
        Apps, such as external applicant tracking systems, social media
        websites, or partner websites (''Third Party Services''). By using our
        Services, you acknowledge you have read and understood this privacy
        policy. For EU GDPR (General Data Protection Regulation), the data
        controller of the data processed through the Service is the customer of
        TimeMarkr who makes available and permits end users to access and use
        the service or anyone on its behalf. For data collected directly on its
        website (for marketing and communication purposes), TimeMarkr is the
        ''Data Controller''.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Information we collect and how we use it</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Personal Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We generally collect and process the following types of Personal
        Information:
      </p>
      <p style={{ textAlign: "justify" }}>
        Personal Information which is being gathered through the Service
        consists of any personal details provided consciously and voluntarily by
        our customer (Employer), end user or the Customer’s administrator or
        through your use of the TimeMarkr platform. This may include your name
        (first and last), nickname, birthdate, gender, nationality, job title,
        phone number(s), date you first started working for your employer,
        department you work in, employee ID/ national security number, address,
        country, city, postcode, family status, spouse’s and other dependents
        name, gender and birth date, your bank account details (bank name,
        account number, branch address), details regarding your salary and work
        (pay period, payment frequency, base salary, gross salary, overtime,
        bonuses, commissions, statutory payments such as sick,
        maternity/paternity leave, salary payment currency, credential regarding
        the right to work in your jurisdiction, tax code, emergency contact
        details (name, relation, phone number(s), email address(es), city,
        country, post code), termination date, termination reason, probation end
        date, status in the system and in the workplace, IP address and other
        unique identifiers, user’s information relating to tax declarations,
        information the customer chooses to collect and other information user
        may choose to provide to TimeMarkr and to its employee.
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Location Information:</strong> We do not ask you for, access, or
        track any location-based information from your mobile device at any time
        while downloading or using the Mobile Apps. However, if you are using
        the TimeMarkr Mobile App, your employer may enable location tracking
        technology for timekeeping purposes. The GDPR legal basis for processing
        this information is the contractual obligation to your employer to
        perform the Services. If you apply for a job at TimeMarkr through the
        Site, you may provide us with your location information by selecting the
        “Locate me” button. We use this information to present available jobs
        near your current location. The GDPR legal basis for processing this
        information is your explicit consent.
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Attendance Logs:</strong> If our Customer (Employer) chose to
        utilize the Time and Attendance Software, we shall retain and process
        biometric device logs of the users to calculate attendance using the
        rules configured by the Customer. The information collected consists of
        employee or attendance id along with timestamps of punches (in and out
        entries)
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Contact Information:</strong> When you express an interest in
        obtaining additional information about the Services, the Site, or Mobile
        Apps, TimeMarkr may ask you to provide your personal contact
        information, such as your name, email address, and phone number. This
        information is used to communicate with you by responding to your
        requests, comments, and questions. The GDPR legal basis for processing
        this information is the legitimate interest in communicating with you
        and answering your questions.
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Device Information:</strong> When using the Mobile Apps, we may
        request access to your device’s camera and photo storage. This allows
        you to take and upload pictures and such access would only be used in
        ways you choose. You may at any time revoke access at the device level.
        We do not access your device’s camera and photo storage without your
        permission. We use mobile analytics software to allow us to better
        understand the functionality of our Mobile Apps on your phone. This
        software may record information such as how often you use the
        application, the events that occur within the application, aggregated
        usage, performance data, and where the application was downloaded from.
        We do not link the information We store within the analytics software to
        any Personal Information you submit within the Mobile Apps. When you
        download and use the Mobile Apps, we automatically collect your device
        information such as operating system version, type, hardware usage
        statistics, etc. The GDPR legal basis for processing this information is
        the contractual obligation to your employer to perform the Services.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        Data Collected as a Service Provider As a service provider, TimeMarkr
        only collects information as per the Customer's (Employer’s)
        requirement. Our Master Subscription Agreement governs the delivery,
        access, and use of the Services and Mobile Apps, including the
        processing of Personal Information and data submitted through Services
        accounts. The Customer (e.g., your employer) controls their Platform and
        any associated client data. If you have any questions about specific
        Platform settings, the processing of Personal Information in the
        Platform, or its privacy practices, please contact the Customer
        administrator of the Platform you use.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr shall use customer data in accordance with the Customer’s
        instructions, applicable terms in the Master Service Agreement,
        Customer’s use of Services functionality, and as required by applicable
        law. Under applicable GDPR, TimeMarkr is a processor of Customer data
        and Customer is the controller.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Sharing of your Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Third-Party Services</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        At times, you may be able to access other Third-Party Services/websites
        through the Site, for example by clicking on links to those Third-Party
        Services from within the Site. We are not responsible for the privacy
        policies and/or practices of these Third-Party Services, and you are
        responsible for reading and understanding those Third-Party Services’
        privacy policies.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Information Shared with Our Service Providers</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We may share your information with third parties who provide services to
        us. These third parties are authorized to use your Personal Information
        only as necessary to provide these services to us. These services may
        include the provision of (i) email services to send marketing
        communications, (ii) mapping services, (iii) customer service or
        support, and (iv) providing cloud computing infrastructure.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Information Shared with Our Sub-Processors</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We employ and contract with people and other entities that perform
        certain tasks on our behalf and those under our control such as an email
        service provider to send emails on our behalf, mapping service
        providers, and customer support providers our 'Sub-Processors'). We may
        need to share Personal Information with our Sub-Processors in order to
        provide Services to you. Unless specified otherwise, our Sub-Processors
        do not have any right to use Personal Information or other information
        We share with them beyond what is necessary to assist us. Onward
        transfer agreements between TimeMarkr and each Sub-Processor cover
        transfers to subsequent third parties. A list of TimeMarkr
        Sub-Processors that process the Personal Information of individuals
        located in the EU can be found here.
      </p>
      

      <table>
  <thead>
    <tr>
      <th>Service</th>
      <th>Description</th>
      <th>Details</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Google Cloud</td>
      <td>
        For rendering google maps in the browser to mark employee locations
        associated with their attendance.
      </td>
      <td>Employee's details</td>
    </tr>
    <tr>
      <td>Google App</td>
      <td>
        Google Meet and Google Calendar shall be used for scheduling purposes.
        This shall depend upon the preferences set by the customer.
      </td>
      <td>Employee's details</td>
    </tr>
    <tr>
      <td>Microsoft Azure</td>
      <td>For hosting TimeMarkr Applications</td>
      <td>Customer's complete details</td>
    </tr>
    <tr>
      <td>Amazon Web Services</td>
      <td>For email communication</td>
      <td>Email IDs of employees</td>
    </tr>
    <tr>
      <td>Mailchimp</td>
      <td>For email communication to prospects</td>
      <td>Name, company name, and email address</td>
    </tr>
    <tr>
      <td>SurveyMonkey</td>
      <td>Customer information is shared/received</td>
      <td>Name, Email, Company name, answers of customers</td>
    </tr>
    <tr>
      <td>Razorpay</td>
      <td>For receiving TimeMarkr subscription-related payments</td>
      <td />
    </tr>
  </tbody>
</table>

      <p style={{ textAlign: "justify" }}>
        <strong>&nbsp;</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>Information Disclosed according to Business Transfers</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        In some cases, we may choose to buy or sell assets. In these types of
        transactions, user information is typically one of the transferred
        business assets. Moreover, if we, or substantially all of our assets,
        were acquired, or if we go out of business or enter bankruptcy, user
        information would be one of the assets that is transferred or acquired
        by a third party. You acknowledge that such transfers may occur and that
        any acquirer of us or our assets may continue to use your Personal
        Information as set forth in this Privacy Policy. You will be notified
        via email and/or a prominent notice on our site of any change in the
        legal owner or uses of your Personal Information, as well as any choices
        you may have regarding your Personal Information.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>
          Information Disclosed for Our Protection and the Protection of Others
        </strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        In certain situations, we may be required to disclose Personal
        Information in response to lawful requests by public authorities,
        including to meet national security or law enforcement requirements. We
        also reserve the right to access, read, preserve, and disclose any
        information as We reasonably believe is necessary to (i) satisfy any
        applicable law, regulation, legal process or governmental request (ii)
        enforce this Privacy Policy, including investigation of potential
        violations hereof, (iii) detect, prevent, or otherwise address fraud,
        security, or technical issues; (iv) respond to user support requests; or
        (v) protect our rights, property, or safety. This includes exchanging
        information with other companies and organizations for fraud protection
        and spam/malware prevention. We require all third parties to respect the
        security of your Personal Information and to treat it following
        applicable laws. We do not allow third-party service providers and
        Sub-Processors We share your Personal Information with to use it for
        their own purposes and only permit them to process your Personal
        Information for specific purposes by our instructions. Except as set
        forth above, you will be notified when your Personal Information is
        shared with third parties and will be able to prevent the sharing of
        this information. Unless We otherwise have your consent, we will only
        share your Personal Information in the ways that are described in this
        Privacy Policy.
      </p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr use and transfer of information received from Google APIs
        shall adhere to{" "}
        <a href="https://developers.google.com/terms/api-services-user-data-policy">
          Google API Services User Data Policy's App's
        </a>
        , including the Limited Use requirement.
      </p>
      <h4 style={{ textAlign: "justify" }}>
        <strong>Data Retention</strong>
      </h4>
      <p style={{ textAlign: "justify" }}>
        Any Customer may request information regarding the storage and retention
        of data (“Audit”) by contacting us. TimeMarkr shall make reasonable
        efforts to respond to the Audit in a reasonable time and subject to
        applicable law and to protect TimeMarkr’s trade secrets (Customer’s
        personnel may be required to execute non-disclosure agreements).
      </p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr will retain data it processes on behalf of its customers only
        for as long as required to provide the Service to its Customers and as
        necessary to comply with its legal obligations, resolve disputes, and
        enforce its agreements. The data in TimeMarkr is backed up for system
        continuity purposes and each backup file may be stored for 30 days.
      </p>
      <p style={{ textAlign: "justify" }}>
        After the termination of services by a customer, an automated process
        will begin that permanently deletes the data in the next cycle (One
        cycle per quarter). Once begun, this process cannot be reversed and data
        will be permanently deleted. In cases where we delete a single user's
        data, some data will not be deleted and shall be kept anonymized.
      </p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr collects and retains metadata and statistical information
        concerning the use of the service which are not subject to the deletion
        procedures in this policy and may be retained by TimeMarkr for no more
        than required to conduct its business. Some data may be retained on our
        third-party service providers’ servers under their retention policies.
        You will not be identifiable from this retained metadata or statistical
        information.
      </p>
      <p style={{ textAlign: "justify" }}>
        Customers may retain Personal Information and other Data about an end
        user that the Controller owns and the end user may have no access. If
        you have any questions about the right of the Customer to retain and
        process your Personal Information you should raise this directly with
        the Customer. You hereby agree not to assert any claim against TimeMarkr
        in this regard and waive any rights regarding such Data and Personal
        Information including the right to view and control such Data and
        Information.
      </p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr may retain anonymized aggregated data for as long as it is
        required to provide its services. Contracts and billing information may
        be retained as TimeMarkr requires but at least 5 years from termination
        or expiration of the relationship with the applicable Customer or party.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Where do we store your Data?</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        The Data we collect is hosted on Amazon Web Services in Central India,
        Singapore, North Europe, South East Asia, and UAE data centers which
        provide advanced security features and are compliant with ISO 27001 and
        other privacy and security standards. TimeMarkr headquarters is based in
        India where we provide customer support services, but no customer data
        is stored, except for customer call data.
      </p>
      <p style={{ textAlign: "justify" }}>
        Therefore, in providing your Personal Information to TimeMarkr, your
        Personal Information will be sent to Central India, Singapore, North
        Europe, South East Asia, UAE (or otherwise outside of the European
        Union), where the local applicable law may provide you with less
        protection than under European Union law. However, any transfer of
        Personal Information from the European Union to these other locations
        will strictly follow the applicable EU GDPR.
      </p>
      <p style={{ textAlign: "justify" }}>
        When your Data is transferred outside of the EEA (European Economic
        Area), we will take all steps reasonably necessary to ensure that your
        Data is subject to appropriate safeguards, such as relying on a
        recognized legal adequacy mechanism, and that it is treated securely and
        in accordance with this privacy policy.
      </p>
      <p style={{ textAlign: "justify" }}>
        <strong>
          Security and Storage of information <br />
        </strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We take great care in implementing, enforcing, and maintaining the
        security of the Service, and our users’ Personal Information. TimeMarkr
        implements, enforces and maintains security policies to prevent
        unauthorized or accidental access to or destruction, loss, modification,
        use or disclosure of personal data and monitor compliance of such
        policies on an ongoing basis. TimeMarkr developed and maintained by
        allsoft® is certified under the ISO 27001:2013.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr limits access to personal data to those of its personnel who:
        (i) require access in order for TimeMarkr to fulfill its obligations
        under this Privacy Policy and agreements executed with TimeMarkr and
        (ii) have been appropriately and periodically trained on the
        requirements applicable to the processing, care and handling of the
        Personal Information (iii) are under confidentiality obligations as
        required under applicable law. TimeMarkr takes steps to ensure that its
        staff who have access to personal data are honest, reliable, competent,
        and periodically adequately trained.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr shall act following its policies to promptly notify Customer
        if any personal data processed by TimeMarkr on behalf of Customer is
        lost, or stolen, or where there has been any unauthorized access to it
        subject to applicable law and instructions from any agency or authority.
        Furthermore, TimeMarkr cooperates with Customers in investigating and
        remedying any such security breach. In any security breach involving
        Personal Information, TimeMarkr shall promptly take remedial measures,
        including without limitation, reasonable measures to restore the
        security of the Personal Information and limit unauthorized or illegal
        dissemination of the Personal Information or any part thereof.
      </p>
      <p style={{ textAlign: "justify" }}>
        TimeMarkr maintains documentation regarding compliance with the
        requirements of the law, including without limitation documentation of
        any known breaches, and holds reasonable insurance policies concerning
        data security.
      </p>
      <p style={{ textAlign: "justify" }}>
        The Service may, from time to time, contain links to external sites. We
        are not responsible for the operation, privacy policies, or the content
        of such sites.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <h5 style={{ textAlign: "justify" }}>
        <strong>Your Rights associated with your information</strong>
      </h5>
      <p style={{ textAlign: "justify" }}>
        In certain countries (for example in the UK and EU) you may have the
        right under applicable local law to request access, change or delete
        specific personal data that we hold about you, or to object to or
        restrict the processing of certain personal information, receive
        personal information in a usable electronic format and transmit it to a
        third party (also known as the right of data portability), and lodge a
        complaint with a local data protection authority.
      </p>
      <p style={{ textAlign: "justify" }}>
        We will contact you if we need additional information from you to honor
        your requests.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        If you want to update the information that we hold about you or if you
        no longer desire our Service, you may correct, delete inaccuracies, or
        modify the information we hold by sending an inquiry to our
        grievances@allsoft.co. Given the nature of the services that we provide,
        we may have shared your personal information with employers and other
        third parties. You may need to make requests to such third parties under
        the instructions in their privacy policies if you require them to update
        the records that they hold about you. If you want to discuss or exercise
        any of your rights under this policy, please contact our
        grievances@allsoft.co.
      </p>
      <h5 style={{ textAlign: "justify" }}>
        <strong>&nbsp;</strong>
      </h5>
      <h4 style={{ textAlign: "justify" }}>
        <strong>Changes to the Privacy Policy</strong>
      </h4>
      <p style={{ textAlign: "justify" }}>
        The terms of this Privacy Policy will govern the use of the Service and
        any information collected in connection therewith, however, TimeMarkr
        may amend or update this Privacy Policy from time to time. The most
        current version of this Privacy Policy will always be posted at:
        https://www.timemarkr.com/privacy-policy. Unless otherwise agreed with
        the Customer, we will endeavor to provide notice of material changes to
        this policy on the website's homepage and (if applicable) via email.
        Such material changes will take effect seven (7) days after such notice
        was provided on our website or sent by email. Otherwise, all other
        changes to this Privacy Policy are effective as of the stated “Last
        Revised” date and your continued use of Services will constitute your
        active acceptance of, and agreement to be bound by, the changes to the
        Privacy Policy.
      </p>
      <p style={{ textAlign: "justify" }}>
        If you have any questions (or comments) concerning this Privacy Policy,
        you are welcome to send us an email or otherwise contact us at
        info@timemarkr.com and we will make an effort to reply within a
        reasonable timeframe, and not over 30 business days.
      </p>
      <p style={{ textAlign: "justify" }}>
        For the purposes of this section only, “personal information” means any
        information that identifies, relates to, describes, is capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a particular consumer or household.” “Personal information” does
        not include publicly available information that is lawfully made
        available to the general public from federal, state, or local government
        records.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Collection, Use, and Disclosure of PI</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We may hold data on you in our role as a service provider to a client
        company, and we may hold data about you on our own behalf. We collect,
        use, and disclose personal information as follows:{" "}
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Categories of Personal Information We May Collect</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        Identifiers: name, postal address, online identifier, internet protocol
        address, email address, phone number.
      </p>
      <p style={{ textAlign: "justify" }}>
        Commercial information: products or services purchased, obtained, or
        considered, or other purchasing or consuming histories or tendencies.
      </p>
      <p style={{ textAlign: "justify" }}>
        Internet or other electronic network activity information: browsing
        history, search history, and information regarding a consumer’s
        interaction with an Internet website, application, or advertisement
      </p>
      <p style={{ textAlign: "justify" }}>
        Audio and Video: if you visit our facilities that employ CCTV, we may
        capture your likeness on video as you pass through secured areas.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>How We Collect Personal Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We have collected the above categories and types of personal information
        directly from individuals through our website or during customer support
        contact, directly from our business customers, and through business
        documents such as our contracts and other shared documents. We also
        collect personal information automatically using cookies and similar
        trackers.
      </p>
      <p style={{ textAlign: "justify" }}>
        Please refer to our cookie policy for further details.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>How We Use Personal Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We may use your personal information for the following business
        purposes:
        <br /> providing the Service to our business customers.
      </p>
      <ul style={{ textAlign: "justify" }}>
        <li>fulfilling your requests.</li>
        <li>communicating with you about our Services or the Site.</li>
        <li>conducting business together.</li>
        <li>
          protecting our legal interests and abiding by our legal obligations.
        </li>
        <li>protecting the security of our systems and facilities.</li>
        <li>
          analyzing our business and website performance; and marketing to you
          (with your consent, as required by applicable law).
        </li>
      </ul>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>How We Use Personal Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We may disclose your personal information to third parties for business
        purposes. These third parties include analytics providers and other
        service providers under contract with us. These service providers are
        prohibited from using your personal information except at our direction.
        We may also disclose your personal information to government entities or
        other third parties associated with legal or tax requirements, or in the
        case of a proposed merger, acquisition, asset sale, or other corporate
        transaction.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Voluntary information:</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        We may collect additional information at other times, including but not
        limited to, when you provide feedback, change your content or email
        preferences, respond to a survey, or communicate with us. Information is
        automatically collected/ tracked while navigating Link to the privacy
        policy of third-party service providers used by the app.
      </p>
      <ul style={{ textAlign: "justify" }}>
        <li>
          Google Play Services{" "}
          <a href="https://www.google.com/policies/privacy/">
            (https://www.google.com/policies/privacy/)
          </a>
        </li>
        <li>
          Google Analytics for Firebase{" "}
          <a href="https://firebase.google.com/policies/analytics">
            (https://firebase.google.com/policies/analytics)
          </a>
        </li>
        <li>
          Firebase Crashlytics{" "}
          <a href="https://firebase.google.com/support/privacy/">
            (https://firebase.google.com/support/privacy/)
          </a>
        </li>
      </ul>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>
          Your Rights as a California Resident <br />
        </strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        If you are a California resident, you have certain rights (subject to
        certain limits under California law), such as:
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Right to Know and Right to Request Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        You may have the right to request that we disclose what personal
        information we collect, use, disclose or sell. You have the right to
        request information about the personal information we’ve collected about
        you within the last twelve months. You may request to know if we have
        collected, sold, or shared with third parties for a business purpose the
        following information:
      </p>
      <ul style={{ textAlign: "justify" }}>
        <li>
          The categories of personal information we have collected about you;
        </li>
        <li>
          The categories of sources of personal information we collected about
          you;
        </li>
        <li>
          Our business or commercial purpose for collecting or selling personal
          information about you;
        </li>
        <li>
          The categories of third parties with whom we share personal
          information;
        </li>
        <li>
          The specific pieces of personal information we have collected about
          you;
        </li>
        <li>
          Whether we have sold your personal information and if so the
          categories of personal information that each category of recipient
          purchased; and
        </li>
        <li>
          Whether we have disclosed your personal information for a business
          purpose and if so, the categories of personal information that each
          category of recipient received.
        </li>
      </ul>
      <p style={{ textAlign: "justify" }}>
        If we find the requests repetitive, excessive, or unfounded in a
        calendar year, we may refuse to act on your request. Hence, it is
        requested to submit your request consciously.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Right to Request Deletion</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        You have the right to request that we delete the personal information we
        have collected about you. Under certain circumstances we may be unable
        to delete your personal information; for example, to comply with legal
        obligations, or for our legitimate business purposes.
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Right to Opt-Out of the Sale of Personal Information</strong>
      </p>
      <p style={{ textAlign: "justify" }}>
        The CCPA provides California residents the right to opt out of sales of
        their personal information to third parties. We do not engage in sale of
        personal information and have not sold the personal information of
        California or Nevada residents in the preceding twelve months. We also
        do not sell the personal information of minors under the age of 16 years
        of age. This fact notwithstanding, you can submit a request to be opted
        out of any future data sales by sending a request to info@allsoft®.co
      </p>
      <p style={{ textAlign: "justify" }}>&nbsp;</p>
      <p style={{ textAlign: "justify" }}>
        <strong>Right to Non-Discrimination</strong>{" "}
      </p>
      <p style={{ textAlign: "justify" }}>
        You have a right not to receive discriminatory treatment for exercising
        your privacy rights under the CCPA.
      </p>
      <p style={{ textAlign: "justify" }}>
        To exercise your rights as a California resident, you may request in the
        following ways:
      </p>
      <p style={{ textAlign: "justify" }}>Contacting us on info@allsoft.co</p>
      <p style={{ textAlign: "justify" }}>
        When you submit a request, we will verify your request and identity, and
        we reserve the right to take additional steps necessary for
        verification. For example, we may seek to establish your identity by
        requesting additional information from you that we can match with
        information that we have in our records.
      </p>
      <p style={{ textAlign: "justify" }}>
        You may designate an authorized agent to make a request to know or a
        request to delete your personal information by providing the authorized
        agent written permission to do so; and by verifying your own identity
        with us directly. We will deny any requests from agents that do not
        submit proof of authorization. Requests submitted via authorized agents
        will be subject to similar verification processes as direct requests.
      </p>
      <p style={{ textAlign: "justify" }}>
        If you wish to exercise your rights about the personal information we
        may hold about you as a service provider to a client company, please
        direct your request(s) to that company.
      </p>
      <p style={{ textAlign: "justify" }}>
        In case you have any questions about data privacy, please reach out to
        info@allsoft.co
      </p>
    </div>
  </div>
</div>

  );
};

export default PrivacyPolicies;