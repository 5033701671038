import React from "react";

interface Props {
  // Define props here
  width?: string;
  height?: string;
  fill?: string;
}

const PhoneSvg: React.FC<Props> = (props) => {
  return (
    <svg
      fill="#000000"
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      id="phone"
      data-name="Line Color"
      xmlns="http://www.w3.org/2000/svg"
      className="icon line-color"
    >
      <path
        id="primary"
        d="M21,15v3.93a2,2,0,0,1-2.29,2A18,18,0,0,1,3.14,5.29,2,2,0,0,1,5.13,3H9a1,1,0,0,1,1,.89,10.74,10.74,0,0,0,1,3.78,1,1,0,0,1-.42,1.26l-.86.49a1,1,0,0,0-.33,1.46,14.08,14.08,0,0,0,3.69,3.69,1,1,0,0,0,1.46-.33l.49-.86A1,1,0,0,1,16.33,13a10.74,10.74,0,0,0,3.78,1A1,1,0,0,1,21,15Z"
        style={{"fill": "none", "stroke": "rgb(0, 0, 0)", "strokeLinecap": "round", "strokeLinejoin": "round", "strokeWidth": 2  }}
      ></path>
    </svg>
  );
};

export default PhoneSvg;
