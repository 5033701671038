import axios, { AxiosResponse } from "axios";

// Define request payload interfaces
interface RegisterPayload {
    client_name: string,
  client_company_name: string,
  client_mobile_number: string,
  client_email_id: string,
  client_team_size: string,
  no_of_license: number,
  subscription_mode: string,
  pg_transaction_id : string,
}

interface OtpPayload {
    client_email_id?: string;
    client_mobile_number?: string;
    otp?: string;
    client_name?: string;
}

// Define response payload interfaces
interface ApiResponse {
    data : {
        message: string;
        status: boolean;
        data :any;
    
    },

message: string;
    status: boolean;
}


// API for user registration
export const registerUser = async (data: RegisterPayload): Promise<AxiosResponse<ApiResponse>> => {
    try {
        const response = await axios.post<RegisterPayload, AxiosResponse<ApiResponse>>(
            'https://admin.timemarkr.com/api/client-register/registerClient',
            data
        );
        return response;
    } catch (error) {
        throw error;
    }
};

// API to send email OTP
export const sendEmailOtp = async (data: OtpPayload): Promise<AxiosResponse<ApiResponse>> => {
    try {
        const response = await axios.post<OtpPayload, AxiosResponse<ApiResponse>>(
            'https://admin.timemarkr.com/api/client-register/generateOTPEMail',
            data
        );
        return response;
    } catch (error) {
        throw error;
    }
};

// API to verify email OTP
// API to verify email OTP
export const verifyEmailOtp = async (data: OtpPayload): Promise<ApiResponse> => {
    try {
        const response = await fetch(
            'https://admin.timemarkr.com/api/client-register/validateOTPEmail',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
        );

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        throw error;
    }
};

// API to verify phone OTP
export const verifyPhoneOtp = async (data: OtpPayload): Promise<ApiResponse> => {
    try {
        const response = await fetch(
            'https://admin.timemarkr.com/api/client-register/validateOTPMobile',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            }
        );

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        throw error;
    }
};

// API to send phone OTP
export const sendPhoneOtp = async (data: OtpPayload): Promise<AxiosResponse<ApiResponse>> => {
    try {
        const response = await axios.post<OtpPayload, AxiosResponse<ApiResponse>>(
            'https://admin.timemarkr.com/api/client-register/generateOTPMobile',
            data
        );
        return response;
    } catch (error) {
        throw error;
    }
};


