import React, { useState } from 'react';
import faq from "../assets/faqimg.jpg"
interface AccordionItem {
  title: string;
  content: string;
}

const AccordionSection: React.FC = () => {
  const items: AccordionItem[] = [
    {
      title: 'How does TimeMakr work?',
      content: 'TimeMakr works by allowing employees to clock in and out using their smartphones or desktop computers. Employers can then monitor attendance records in real-time through the app\'s intuitive dashboard.',
    },
    {
      title: 'Is TimeMakr suitable for remote teams?',
      content: 'Yes, TimeMakr is perfect for remote teams as it allows employees to clock in and out from anywhere with internet access. Employers can easily monitor remote employees\' attendance and work hours without any hassle.',
    },
    {
      title: 'How secure is TimeMakr?',
      content: 'TimeMakr takes data security seriously and employs industry-standard encryption protocols to ensure the confidentiality and integrity of user data. Additionally, access to sensitive information is restricted based on user roles and permissions.',
    },
    {
      title: 'What sets TimeMakr apart from other attendance management solutions?',
      content: 'TimeMakr stands out for its user-friendly interface, robust features, customizable reporting, and seamless integration capabilities. With TimeMakr, you can simplify attendance management and focus on what matters most – growing your business.',
    },
  ];
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="">
      <div className="container">
      <div className="row ">
      <div className="col-12 col-lg-7 order-lg-1 ">
          {items.map((item, index) => (
            <div className="" key={index}>
              <div className="accordion single-open ">
                <div
                  className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <h5 className="fw-medium">{item.title}</h5>
                </div>
                <div className={`accordion-content ${activeIndex === index ? 'active' : ''}`}>
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 col-lg-5 order-lg-2 ">
          <img src={faq} loading="lazy" alt="" className="img-fluid" />
        </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionSection;
