import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  Button,
  useTheme,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  CircularProgress,
  DialogActions,
  Stack,
  DialogTitle,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OTPInput from "./input/OTPInput";
import {
  registerUser,
  sendEmailOtp,
  sendPhoneOtp,
  verifyEmailOtp,
  verifyPhoneOtp,
} from "../services/registrationService";
import LottieAnimation from "./LottieAnimation";
import PersonIcon from "@mui/icons-material/Person";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import PhoneIcon from "@mui/icons-material/Phone";
import PaymentsIcon from "@mui/icons-material/Payments";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RUPEE } from "../utils/constants";

interface Props {
  open: boolean;
  onClose: () => void;
  subscription?: string;
}

interface FormData {
  name: string;
  companyName: string;
  email?: string;
  phoneNumber?: string;
  client_team_size: string;
  no_of_license?: number;
}

const RegisterModal: React.FC<Props> = ({
  open,
  onClose,
  subscription = "m",
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const teamSizeOptions = [
    { value: "1-10", label: "1-10" },
    { value: "11-50", label: "11-50" },
    { value: "51-100", label: "51-100" },
    { value: "101-500", label: "101-500" },
    { value: "500+", label: "500+" },
  ];
  const [emailOtpSent, setEmailOtpSent] = useState({
    isSent: false,
    isVerified: false,
    resend: false,
    resendTime: 0,
    message: "",
  });
  const [phoneOtpSent, setPhoneOtpSent] = useState({
    isSent: false,
    isVerified: false,
    resend: false,
    resendTime: 0,
    message: "",
  });
  const [loading, setLoading] = useState({
    email: false,
    phone: false,
    verify: false,
  });

  const [step, setStep] = useState(0); // Step 1: Email, Step 2: Phone
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
    status: false,
  });

  const [otp, setOtp] = useState({
    type: "",
    otp: "",
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleOTPComplete = (data: any) => {
    setLoading({
      email: otp.type === "email" ? true : false,
      phone: otp.type === "phone" ? true : false,
      verify: true,
    });
    otp.type === "email"
      ? verifyEmailOtp({ client_email_id: values.email, otp: otp.otp })
        .then((res) => {
          setLoading({
            email: false,
            phone: false,
            verify: false,
          });

          setSnackbarOpen({
            open: true,
            message: res?.message,
            status: res?.status,
          });

          setEmailOtpSent({
            isSent: true,
            isVerified: res?.status || false,
            resend: false,
            resendTime: 0,
            message: res?.message || "",
          });
          if (res?.status) {
            handleNext();
          }
        })
        .catch((error: any) => {
          console.log(error, "cd otp error");
          setSnackbarOpen({
            open: true,
            message: error?.response?.data?.message,
            status: error?.response?.data?.status,
          });
        })
      : verifyPhoneOtp({
        client_mobile_number: values.phoneNumber,
        otp: otp.otp,
      })
        .then((res) => {
          setSnackbarOpen({
            open: true,
            message: res?.message,
            status: res?.status,
          });
          setLoading({
            email: false,
            phone: false,
            verify: false,
          });
          if (res?.status) {
            handleNext();
          }
          setPhoneOtpSent({
            isSent: true,
            isVerified: res?.status,
            message: res?.message,

            resend: false,
            resendTime: 0,
          });
        })
        .catch((error: any) => {
          console.log(error, "phone otp error");
          setSnackbarOpen({
            open: true,
            message: error?.response?.data?.message,
            status: error?.response?.data?.status,
          });
        });
  };

  const defaultValues = {
    name: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    client_team_size: "",
    no_of_license: 1,
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    companyName: Yup.string().required("Company Name is required"),
    email: Yup.string().email("Invalid email"),
    phoneNumber: Yup.string()

      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    client_team_size: Yup.string().required("Team Size is required"),
    no_of_license: Yup.number(),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const values = watch();

  const [emailOtpResendTimer, setEmailOtpResendTimer] = useState(0);
  const [phoneOtpResendTimer, setPhoneOtpResendTimer] = useState(0);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    // Clean up function to remove the script tag when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); //
  const handlePhoneOtpSend = ({ resend = false }) => {
    setLoading({
      email: false,
      phone: true,
      verify: false,
    });
    sendPhoneOtp({ client_mobile_number: values.phoneNumber || "" })
      .then((res) => {
        setSnackbarOpen({
          open: true,
          message: res?.data?.message || "",
          status: res?.data?.status || false,
        });
        setLoading({
          email: false,
          phone: false,
          verify: false,
        });

        setPhoneOtpSent({
          isSent: res?.data?.status || false,
          isVerified: false,
          resend: resend,
          resendTime: 60,
          message: res?.data?.message || "",
        });
        setPhoneOtpResendTimer(30);
      })
      .catch((error) => {
        alert("Error in sending OTP");
      });
  };

  const handleEmailOtpSend = ({ resend = false }) => {
    // Logic to send OTP to email
    setLoading({
      email: true,
      phone: false,
      verify: false,
    });
    sendEmailOtp({ client_email_id: values.email, client_name: values.name })
      .then((res) => {
        setSnackbarOpen({
          open: true,
          message: res?.data?.message,
          status: res?.data?.status || false,
        });

        setLoading({
          email: false,
          phone: false,
          verify: false,
        });

        setEmailOtpSent({
          isSent: res?.data?.status,
          isVerified: false,
          resend: resend,
          resendTime: 60,
          message: res?.data?.message,
        });
        setEmailOtpResendTimer(30);
      })
      .catch((error) => {
        setSnackbarOpen({
          open: true,
          message: error?.response?.data?.message,
          status: error?.response?.data?.status,
        });
      });
  };

  //  after 30 seconds resend otp  email  and phone number
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | null | undefined = null;
    if (emailOtpResendTimer > 0) {
      timer = setTimeout(() => {
        setEmailOtpResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setEmailOtpResendTimer(0);
    }

    return () => clearTimeout(timer as NodeJS.Timeout);
  }, [emailOtpResendTimer]);

  //  after 30 seconds resend otp  email  and phone number
  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | null | undefined = null;
    if (phoneOtpResendTimer > 0) {
      timer = setTimeout(() => {
        setPhoneOtpResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setPhoneOtpResendTimer(0);
    }

    // Clear the previous timer when the component unmounts or when the effect is re-triggered.
    return () => clearTimeout(timer as NodeJS.Timeout);
  }, [phoneOtpResendTimer]);

  const submitStep = async (data: FormData) => { };
  const saveOtp = (data: any) => {
    setOtp(data);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen({ ...snackbarOpen, open: false });
  };
  const openPayModal = () => {
    handleNext();
    // below code will be used when payment gateway is needed
    // setLoading({
    //   email: false,
    //   phone: false,
    //   verify: true,
    // });
    // const options = {
    //   key: "rzp_test_8WwsfYMEUwvI7b", // Replace with your Razorpay API key
    //   amount:
    //     subscription === "m"
    //       ? 99 * 100 * (values?.no_of_license ?? 0)
    //       : 999 * 100 * (values?.no_of_license ?? 0),
    //   currency: "INR",
    //   name: values.companyName,
    //   description: "Team License",
    //   subscription_mode: true, // Indicates that the payment is for a subscription
    //   handler: function (response: any) {
    //     registerUser({
    //       client_name: values.name,
    //       client_company_name: values.companyName,
    //       client_email_id: values.email ?? "",
    //       client_mobile_number: values.phoneNumber ?? "",
    //       client_team_size: values.client_team_size,
    //       no_of_license: values.no_of_license ?? 0,
    //       pg_transaction_id: response.razorpay_payment_id,
    //       subscription_mode: subscription,
    //     })
    //       .then((res) => {
    //         setLoading({
    //           email: false,
    //           phone: false,
    //           verify: false,
    //         });
    //         if (res?.status) {
    //           handleNext();
    //         }
    //         values.name = "";
    //         values.companyName = "";
    //         values.email = "";
    //         values.phoneNumber = "";
    //         values.client_team_size = "";
    //         values.no_of_license = 0;
    //       })
    //       .catch((error) => {
    //         setSnackbarOpen({
    //           open: true,
    //           message: error?.response?.data?.message,
    //           status: error?.response?.data?.status,
    //         });
    //       });
    //     setStep(step + 1);
    //     setSnackbarOpen({
    //       open: true,
    //       message: "Payment successful",
    //       status: true,
    //     });
    //     setPaymentSuccess(true);
    //   },
    //   prefill: {
    //     name: values.name,
    //     email: values.email,
    //     contact: values.phoneNumber,
    //   },
    //   theme: {
    //     color: "#F37254",
    //   },
    // };

    // const rzp = new (window as any).Razorpay(options);
    // rzp.open();
    // // if user closes the payment modal, set the loading state to false
    // rzp.on("payment.failed", function (response: any) {
    //   setLoading({
    //     email: false,
    //     phone: false,
    //     verify: false,
    //   });
    //   setSnackbarOpen({
    //     open: true,
    //     message: "Payment failed",
    //     status: false,
    //   });
    // });

    // rzp.on("payment.cancel", function (response: any) {
    //   setLoading({
    //     email: false,
    //     phone: false,
    //     verify: false,
    //   });
    //   setSnackbarOpen({
    //     open: true,
    //     message: "Payment cancelled",
    //     status: false,
    //   });
    // });

    // rzp.on("payment.error", function (response: any) {
    //   setLoading({
    //     email: false,
    //     phone: false,
    //     verify: false,
    //   });
    //   setSnackbarOpen({
    //     open: true,
    //     message: "Payment error",
    //     status: false,
    //   });
    // });
  };

  const steps = [
    {
      name: "Company ",
      icon: (
        <PersonIcon
          sx={{
            width: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
            height: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
        />
      ),
    },
    {
      name: "Email ",
      icon: (
        <MarkunreadIcon
          sx={{
            width: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
            height: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
        />
      ),
    },
    {
      name: "Phone ",
      icon: (
        <PhoneIcon
          sx={{
            width: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
            height: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
        />
      ),
    },
    {
      name: "Payment ",
      icon: (
        <PaymentsIcon
          sx={{
            width: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
            height: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
        />
      ),
    },
    {
      name: "Finish",
      icon: (
        <CheckCircleIcon
          sx={{
            width: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
            height: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "1rem",
            },
          }}
        />
      ),
    },
  ];
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          margin: "10px",
          boxShadow: "none",
          width: "100%",
        },
      }}
    >
      <DialogTitle>Registration</DialogTitle>
      <div className="form-wizard-steps form-wizard-tolal-steps-4 ">
        {steps.map((step, index) => {
          return (
            <div
              className={`form-wizard-step  ${activeStep === index ? "active" : ""
                }`}
              key={index}
            >
              <div className="form-wizard-step-icon ">{step.icon}</div>
              <p className=" w-100 text-center">{step.name}</p>
            </div>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          marginTop: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Stack
            sx={{
              marginBottom: "2rem",
              flex: 1,
              padding: {
                xs: "0.5rem",
                md: "1rem"
              },
              width: "100%",
              maxHeight: {
                xs: "50vh",
                sm: "50vh",
                md: "35vh",
                lg: "35vh",
                xl: "25vh",
              },
              height: "100%",
              minHeight: {
                xs: "50vh",
                sm: "50vh",
                md: "35vh",
                lg: "35vh",
                xl: "25vh",
              },
            }}
          >
            <form onSubmit={handleSubmit(submitStep)}>
              {activeStep === 0 && (
                <div>
                  <TextField
                    label="Name"
                    variant="outlined"
                    placeholder="Enter your name"
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ""}
                    fullWidth
                    InputLabelProps={{ style: { color: "#383838" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#ddd",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#ddd",
                        },

                        "&.Mui-focused": {
                          color: "#000",
                        },
                      },
                    }}
                    style={{ marginBottom: "1rem" }}
                  />
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    placeholder="Enter your company name"
                    {...register("companyName")}
                    error={!!errors.companyName}
                    helperText={
                      errors.companyName ? errors.companyName.message : ""
                    }
                    fullWidth
                    style={{ marginBottom: "1rem" }}
                    InputLabelProps={{ style: { color: "#383838" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ddd",
                        },
                        "&:hover fieldset": {
                          borderColor: "#ddd",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#ddd",
                        },

                        "&.Mui-focused": {
                          color: "#000",
                        },
                      },
                    }}
                  />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel
                      id="demo-simple-select-autowidth-label"
                      style={{ color: "#383838" }}
                    >
                      Team Size
                    </InputLabel>
                    <Select
                      label="Team Size"
                      variant="outlined"
                      placeholder="Select team size"
                      {...register("client_team_size")}
                      error={!!errors.no_of_license}
                      fullWidth
                      style={{ marginBottom: "1rem" }}
                      sx={{
                        color: "#000",

                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ddd",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ddd",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ddd",
                        },
                      }}
                      defaultValue={values.client_team_size}
                    >
                      {teamSizeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {activeStep === 1 && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <TextField
                      label="Email address"
                      type="email"
                      variant="outlined"
                      placeholder="Enter email"
                      {...register("email")}
                      error={!!errors.email}
                      helperText={
                        errors.email ? (
                          errors.email.message
                        ) : (
                          <p
                            style={{
                              color:
                                emailOtpSent.isSent || emailOtpSent.isVerified
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {emailOtpSent.message}
                          </p>
                        )
                      }
                      //
                      fullWidth
                      InputProps={{}}
                      style={{ marginBottom: "1rem" }}
                      InputLabelProps={{ style: { color: "#383838" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },
                          border: "1px solid #ddd",
                          "&.Mui-focused": {
                            color: "#000",
                          },
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "0.5rem" }}
                      size="small"
                      onClick={() => {
                        handleEmailOtpSend({ resend: false });
                      }}
                      disabled={values.email === ""}
                      //
                      sx={{
                        backgroundColor: "#FFD78A",
                        color: "#383838",

                        boxShadow: "none",
                        height: "3.5rem",

                        "&:hover": {
                          backgroundColor: "#FFD78A",
                          color: "#383838",
                        },
                      }}
                    >
                      {loading.email ? (
                        <CircularProgress
                          sx={{
                            "& .MuiCircularProgress-svg": {
                              color: "#383838",
                            },
                            "& .MuiCircularProgress-circle": {
                              stroke: "black",
                            },

                            width: "25px",
                            height: "25px",
                          }}
                          size="2rem"
                        />
                      ) : (
                        "Send"
                      )}
                    </Button>
                  </div>

                  {emailOtpSent.isSent && !emailOtpSent.isVerified && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="mb-4"
                    >
                      <div style={{ flex: 4 }}>
                        <OTPInput
                          length={6}
                          onComplete={saveOtp}
                          type="email"
                        />
                      </div>
                    </div>
                  )}
                  {emailOtpResendTimer !== 0 &&
                    emailOtpSent.isSent &&
                    !emailOtpSent?.isVerified && (
                      <p style={{ color: "red" }}>
                        {" "}
                        Resend OTP in {emailOtpResendTimer} seconds
                      </p>
                    )}

                  {emailOtpResendTimer === 0 &&
                    emailOtpSent.isSent &&
                    !emailOtpSent?.isVerified && (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ marginLeft: "0.5rem", width: "10%" }}
                        size="small"
                        onClick={() => {
                          if (emailOtpResendTimer === 0) {
                            handleEmailOtpSend({ resend: true });
                          }
                        }}
                        disabled={emailOtpResendTimer !== 0}
                      >
                        Resend
                      </Button>
                    )}
                </div>
              )}
              {activeStep === 2 && (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      placeholder="Enter your phone number"
                      {...register("phoneNumber")}
                      error={!!errors.phoneNumber}
                      helperText={
                        errors.phoneNumber ? (
                          errors.phoneNumber.message
                        ) : (
                          <p
                            style={{
                              color:
                                phoneOtpSent.isSent || phoneOtpSent.isVerified
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {phoneOtpSent.message}
                          </p>
                        )
                      }
                      fullWidth
                      inputMode="numeric"
                      inputProps={{ pattern: "[1-9][0-9]{9}", maxLength: 10 }}
                      InputLabelProps={{ style: { color: "#383838" } }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused": {
                            color: "#000",
                          },
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "1rem" }}
                      size="small"
                      onClick={() => {
                        handlePhoneOtpSend({ resend: false });
                      }}
                      disabled={
                        values.phoneNumber === "" ||
                        values.phoneNumber?.length !== 10
                      }
                      sx={{
                        backgroundColor: "#FFD78A",
                        color: "#383838",

                        boxShadow: "none",
                        height: "3.5rem",

                        "&:hover": {
                          backgroundColor: "#FFD78A",
                          color: "#383838",
                        },
                      }}
                    >
                      {loading.email && (
                        <CircularProgress
                          sx={{
                            "& .MuiCircularProgress-svg": {
                              color: "#383838",
                            },
                            "& .MuiCircularProgress-circle": {
                              stroke: "black",
                            },

                            width: "25px",
                            height: "25px",
                          }}
                          size="2rem"
                        />
                      )}
                      Send{" "}
                    </Button>
                  </div>

                  {phoneOtpSent.isSent && !phoneOtpSent.isVerified && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="mb-4"
                    >
                      <div style={{ flex: 4 }}>
                        <OTPInput
                          length={6}
                          onComplete={saveOtp}
                          type="phone"
                        />
                      </div>
                    </div>
                  )}

                  {phoneOtpResendTimer !== 0 &&
                    phoneOtpSent.isSent &&
                    !phoneOtpSent?.isVerified && (
                      <p style={{ color: "red" }}>
                        {" "}
                        Resend OTP in {phoneOtpResendTimer} seconds
                      </p>
                    )}
                  {phoneOtpResendTimer === 0 &&
                    phoneOtpSent.isSent &&
                    !phoneOtpSent?.isVerified && (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{ marginLeft: "0.5rem", width: "10%" }}
                        size="small"
                        onClick={() => {
                          if (phoneOtpResendTimer === 0) {
                            handlePhoneOtpSend({ resend: true });
                          }
                        }}
                        disabled={phoneOtpResendTimer !== 0}
                      >
                        Resend
                      </Button>
                    )}
                </div>
              )}
              {activeStep === 3 && (
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: "1rem", width: "100%" }}>
                    <TextField
                      {...register("no_of_license")}
                      label="Number of License"
                      error={!!errors.no_of_license}
                      helperText={errors.no_of_license?.message}
                      InputLabelProps={{ style: { color: "#383838" } }}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ddd",
                          },
                          "&:hover fieldset": {
                            borderColor: "#ddd",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ddd",
                          },

                          "&.Mui-focused": {
                            color: "#000",
                          },
                          // label color should be black
                        },
                      }}
                      type="number"
                      style={{ marginBottom: "1rem" }}
                    />
                  </div>
                  {emailOtpSent.isVerified && phoneOtpSent.isVerified ? (
                    <div className=" my-2 ">
                      <p style={{ color: "#4caf50" }} className="text-center">
                        You have successfully verified your email and phone
                        number
                      </p>

                      {/* pay now  price   */}
                      <p style={{ color: "#4caf50", }} className="text-center">
                        Registration Fee :{" "}
                        <span className="me-2"> {RUPEE}0</span>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                          {RUPEE}{subscription === "m"
                            ? 99 * (values?.no_of_license ?? 1)
                            : 999 * (values?.no_of_license ?? 1)}
                        </span>
                      </p>
                    </div>
                  ) : null}
                </div>
              )}

              {activeStep === 4 && (
                <div>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "green",
                      marginBottom: "1rem",
                    }}
                  >
                    Thank You for registering with us. Please check your mail{" "}
                    {values.email} for your login credentials.
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <LottieAnimation
                      src="https://lottie.host/8cfe2f4a-724d-4db2-b648-3185cc5ec89f/FX1cvyCn8a.json"
                      width="150px"
                      height="150px"
                      loop
                      autoplay
                    />
                  </div>
                </div>
              )}
              <div>
                <DialogActions
                  sx={{
                    // fix the position of the button to the bottom of the modal
                    position: "absolute",
                    bottom: "0",

                    width: "95%",
                  }}
                >
                  {activeStep !== 0 && activeStep !== 4 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setActiveStep((prevActiveStep) => prevActiveStep - 1);
                        }}
                        sx={{
                          backgroundColor: "#FFD78A",
                          color: "#383838",
                          padding: "0.6rem 2rem",
                          boxShadow: "none",
                          margin: "0.5rem",
                          "&:hover": {
                            backgroundColor: "#FFD78A",
                            color: "#383838",
                          },
                        }}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    </div>
                  )}
                  {activeStep !== 4 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"

                        onClick={() => {
                          if (activeStep === 0) {
                            handleNext();
                          } else if (activeStep === 1) {
                            if (!emailOtpSent.isVerified) {
                              handleOTPComplete({
                                otp: otp.otp,
                                type: "email",
                              });
                            } else {
                              handleNext();
                            }
                          } else if (activeStep === 2) {
                            if (!phoneOtpSent.isVerified) {
                              handleOTPComplete({
                                otp: otp.otp,
                                type: "phone",
                              });
                            } else {
                              handleNext();
                            }
                          } else if (activeStep === 3) {
                            openPayModal();
                          } else {
                            handleNext();
                          }
                          // activeStep === 0 ? handleNext : activeStep === 1 ? handleOTPComplete : activeStep === 2 ? handleOTPComplete : activeStep === 3 ? handleNext : handleNext
                        }}
                        sx={{
                          backgroundColor: "#FFD78A",
                          color: "#383838",
                          padding: "0.6rem 2rem",

                          boxShadow: "none",
                          margin: "0.5rem",
                          "&:hover": {
                            backgroundColor: "#FFD78A",
                            color: "#383838",
                          },
                          // dont wrap the text in the button
                          whiteSpace: "nowrap",
                        }}
                        disabled={
                          (activeStep === 0 && values.name === "") ||
                          (activeStep === 0 && values.companyName === "") ||
                          (activeStep === 0 &&
                            values.client_team_size === "") ||
                          (activeStep === 1 && values.email === "") ||
                          (activeStep === 1 && otp.otp === "") ||
                          (activeStep === 2 && values.phoneNumber === "") ||
                          (activeStep === 2 && otp.type !== "phone") ||
                          (activeStep === 2 && otp.otp === "") ||
                          (activeStep === 3 && values.no_of_license === 0) ||
                          (activeStep === 3 && values.no_of_license === 0)
                        }
                      >
                        {loading.verify ? (
                          <CircularProgress
                            sx={{
                              "& .MuiCircularProgress-svg": {
                                color: "#383838",
                              },
                              "& .MuiCircularProgress-circle": {
                                stroke: "black",
                              },

                              width: "20px",
                              height: "20px",
                            }}
                            size="1rem"
                          />
                        ) :
                          activeStep === 4
                            ? "Finish"
                            : activeStep === 3
                              ? "Next"
                              : "Next"}
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    </div>
                  )}
                  {activeStep === 4 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onClose();
                        }}
                        sx={{
                          backgroundColor: "#FFD78A",
                          color: "#383838",
                          boxShadow: "none",
                          "&:hover": {
                            backgroundColor: "#FFD78A",
                            color: "#383838",
                          },
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        <KeyboardArrowLeft /> Close
                      </Button>
                    </div>
                  )}
                  {activeStep === 4 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      {" "}
                      <Button
                        onClick={() => {
                          onClose();
                          window.open("http://admin.timemarkr.com/", "_blank");
                        }}
                        sx={{
                          backgroundColor: "#FFD78A",
                          color: "#383838",

                          boxShadow: "none",

                          "&:hover": {
                            backgroundColor: "#FFD78A",
                            color: "#383838",
                          },
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Login{" "}
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    </div>
                  )}
                </DialogActions>
              </div>
            </form>
          </Stack>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          message={snackbarOpen.message}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor:
                snackbarOpen.status === true ? "#4caf50" : "#f44336",
            },
            width: "100%",
          }}
        />
      </div>
    </Dialog>
  );
};

export default RegisterModal;
