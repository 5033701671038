import React from 'react';

interface Props {
  // Define props here
  width?: string;
  height?: string;
  fill?: string;
}

const FacebookSvg: React.FC<Props> = (props) => {
  return (
    <svg width={
      props.width ? props.width : "20px"
    } height={
      props.height ? props.height : "20px"
    } viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  
    <path d="M7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5ZM7.5 14.5V6.5C7.5 5.39543 8.39543 4.5 9.5 4.5H10M5 8.5H10" stroke={
      props.fill ? props.fill : "black"
    } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default FacebookSvg;