import { Route, Routes } from "react-router-dom";
import Landing from "../components/Landing";
import PrivacyPolicies from "../components/PrivacyPolicies";
import PageLayout from "../layout/PageLayout";
import TermsAndCondition from "../components/TermsAndCondition";
import RefundPolicy from "../components/RefundPolicy";
import Error404 from "../components/Error404";


const AppRoutes: React.FC = () => {

  return (
    <Routes>

      <Route path="/" element={<Landing />} />
      <Route path="/privacypolicy" element={<PageLayout><PrivacyPolicies /></PageLayout>} />
      <Route path="/refundpolicy" element={<PageLayout><RefundPolicy /></PageLayout>} />
      <Route path="/termsandcondition" element={<PageLayout><TermsAndCondition /> </PageLayout>} />
      <Route path="*" element={<PageLayout><Error404 /> </PageLayout>} />


    </Routes>
  );
};

export default AppRoutes;
