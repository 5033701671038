import React, { useRef, useState } from "react";
import RegisterModal from "./RegisterModal";

const PricingPlansSection: React.FC = () => {
  const pricingRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [subscription, setSubscription] = useState("m"); // ['monthly', 'yearly'
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleRegisterClick = () => {
    handleShow();
    setSubscription("m");
  }

  return (
    <div className="bg-yellow-light m-0 py-4 " ref={pricingRef} id="register">
      <div className="container py-4">
        <div className="row align-items-center ">
          <div className="col-12 col-lg-5">
            <h6 className="d-inline-block bg-yellow box-shadow border-radius px-3 py-2 line-height-140 font-small uppercase letter-spacing-1 mb-3">
              <span className="text-gradient-6">Pricing Plans</span>
            </h6>
            <h2>Upgrade Your Attendance Management with TimeMarkr Today!</h2>
            <p>
              Say farewell to outdated attendance methods and embrace the future
              with TimeMarkr. Experience the convenience of GPS-enhanced
              tracking and selfie verification, ensuring accurate attendance
              records and enhanced security. Contact us now and redefine the way
              you manage your employee's attendance!
            </p>
          </div>
          <div className="col-12 col-lg-7">
            <div className="row g-4">

              <div className="col-12 col-md-6">
                <div className="bg-white box-shadow hover-float border-radius p-4 p-lg-5">
                  <h6 className="font-small fw-medium uppercase letter-spacing-1">
                    Monthly
                  </h6>
                  <div className="mt-3">
                    <div className="">
                      <h1
                        className="line-height-100 fw-medium mb-0 "
                        style={{
                          flex: 1,
                          position: "relative",
                        }}
                      >
                        <div style={{
                          position: "absolute",
                          top: "45%",
                          left: "0",

                          height: "1px",
                          border: "1px solid #555555",

                        }} className="del-width" ></div>
                        <div style={{
                          color: "#555555"
                        }}>₹. 99.00</div>
                      </h1>
                      <h6 className="d-inline-block   px-0 py-2 line-height-140 font-small uppercase letter-spacing-1 mt-1 mb-3" style={{
                        color: "red"
                      }}>
                        <span className="">
                          Freely Available till October 2024
                        </span>
                      </h6>
                    </div>
                  </div>
                  <ul className="list-unstyled mt-4">
                    <li> Per User</li>

                    <li>24/7 Support</li>
                    <li>Future Updates</li>
                  </ul>
                  <div className="mt-4 w-100  d-flex justify-content-center  align-items-center cursor-pointer" >
                    <button
                      type="button"
                      className="button  button-md button-font-2 button-gradient-6 button-rounded mt-4"
                      onClick={handleRegisterClick}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="bg-white box-shadow hover-float border-radius p-4 p-lg-5">
                  <h6 className="font-small fw-medium uppercase letter-spacing-1">
                    Yearly
                  </h6>
                  <div className="mt-3">
                    <div className="">
                      <h1
                        className="line-height-100 fw-medium mb-0 "
                        style={{
                          flex: 1,
                          position: "relative",
                        }}
                      >
                        <div style={{
                          position: "absolute",
                          top: "45%",
                          left: "0",

                          height: "1px",
                          border: "1px solid #555555",

                        }} className="del-width-1" ></div>
                        <div style={{
                          color: "#555555"
                        }}>₹. 999.00</div>
                      </h1>
                      <h6 className="d-inline-block   px-0 py-2 line-height-140 font-small uppercase letter-spacing-1 mt-1 mb-3" style={{
                        color: "red"
                      }}>
                        <span className="">
                          Freely Available till October 2024
                        </span>
                      </h6>
                    </div>
                  </div>
                  <ul className="list-unstyled mt-4">
                    <li> Per User</li>

                    <li>24/7 Support</li>
                    <li>Future Updates</li>
                  </ul>
                  <div className="mt-4 w-100  d-flex justify-content-center  align-items-center cursor-pointer" >
                    <button
                      type="button"
                      className="button button-md button-font-2 button-gradient-6 button-rounded mt-4"
                      onClick={handleRegisterClick}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              </div>

            </div>{" "}

          </div>
        </div>{" "}

      </div>{" "}

      {showModal && (
        <RegisterModal
          open={showModal}
          onClose={handleClose}

          subscription={subscription}
        />
      )}
    </div>
  );
};

export default PricingPlansSection;
