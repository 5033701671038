import React from 'react';

const WorkingApproach: React.FC = () => {
  return (
   
      <div className="container ">
        <div className="row">
        <div className="">
          <div className="col-12 text-center">
            <h6 className="d-inline-block bg-yellow border-radius px-3 py-2 line-height-140 font-small uppercase letter-spacing-1 mb-3">
              <span className="text-gradient-6">Process</span>
            </h6>
            <h2 className="">How It Works</h2>
          </div>
        </div>
        <div className="row g-4 g-lg-5">
          
          <div className="col-12 col-md-6 col-lg-3">
            <div className="circle-box-lg bg-white box-shadow mb-4 ">
              <h3 className="text-gradient-7 mb-3">1</h3>
            </div>
            <h5 className="mb-3">Download & Install</h5>
            <p className="mb-0">Get started by downloading TimeMarkr from the App Store. It's available for Android devices.  

            </p>
          </div>
          
          <div className="col-12 col-md-6 col-lg-3">
            <div className="circle-box-lg bg-white box-shadow mb-4 ">
              <h3 className="text-gradient-7 mb-3">2</h3>
            </div>
            <h5 className="mb-3">Sign In</h5>
            <p className="mb-0"> Sign in to your TimeMarkr account. If you don't have an account, you can create one in a few simple steps.</p>
          </div>
          
          <div className="col-12 col-md-6 col-lg-3">
            <div className="circle-box-lg bg-white box-shadow mb-4 ">
              <h3 className="text-gradient-7 mb-3">3</h3>
            </div>
            <h5 className="mb-3">Mark Your Attendance</h5>
            <p className="mb-0">When it's time to clock in, open the app and follow the prompts to mark your attendance. A quick selfie ensures the accuracy of the record.</p>
          </div>
          
          <div className="col-12 col-md-6 col-lg-3">
            <div className="circle-box-lg bg-white box-shadow mb-4 ">
              <h3 className="text-gradient-7 mb-3">4</h3>
            </div>
            <h5 className="mb-3">Access Reports</h5>
            <p className="mb-0">Stay informed about your attendance history and explore detailed reports to gain insights into your work patterns.</p>
          </div>
        </div>
        </div>
      </div>

  );
};

export default WorkingApproach;
