import React from 'react';
import FacebookSvg from '../assets/svg/FacebookSvg';
import InstagramSvg from '../assets/svg/InstagramSvg';
import LinkedInSvg from '../assets/svg/LinkedInSvg';
import ReCaptcha from './ReCaptcha';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <footer className="footer-distributed ">
        <div className="container " style={{
          flexWrap: "wrap",
        }}>



          <div className="footer-left">
            <p className="footer-links">
              <a href="/privacypolicy"   >Privacy Policy</a>
              <a href="/refundpolicy"   >Refund Policy</a>
              <a href="/termsandcondition"   >Terms & Conditions</a>
            </p>

            <p>TimeMarkr &copy; {new Date().getFullYear()} , All Rights Reserved</p>
          </div>
          <div className="footer-right">

            <a href="#"><FacebookSvg fill="#fff" /></a>
            <a href="#"><InstagramSvg fill="#fff" /></a>
            <a href="#"><LinkedInSvg fill="#fff" /></a>


          </div>
        </div>
        <ReCaptcha />
      </footer>
    </div>
  );
}

export default Footer;
